<!--
 * @FilePath: \zhishe-pc\src\views\sign\companySign.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-29 11:21:46
-->
<template>
  <div class="signDetail">
    <div class="signStepsTitle">
      <el-steps :active="activeStep" align-center>
        <el-step title="签约协议"></el-step>
        <el-step title="实名认证"></el-step>
        <el-step title="企业认证"></el-step>
        <el-step title="签约审核"></el-step>
      </el-steps>
    </div>
    <div class="stepForm">
      <!-- 步骤一（签约协议）  -->
      <SignAgreement
        @nextStep="nextStep"
        v-show="activeStep == 1"
      ></SignAgreement>
      <!-- 步骤二（实名认证）-->
      <CompanyAuthentication
        @nextStep="nextStep"
        v-show="activeStep == 2"
      ></CompanyAuthentication>
      <!-- 步骤三（企业认证） -->
      <Qualification
        @nextStep="nextStep"
        v-show="activeStep == 3"
      ></Qualification>
      <!-- 步骤四（提交成功） -->
      <SubmitSucceed v-show="activeStep == 4"></SubmitSucceed>
    </div>
  </div>
</template>

<script>
import SignAgreement from "./components/SignAgreement";
import CompanyAuthentication from "./components/CompanyAuthentication";
import SubmitSucceed from "./components/SubmitSucceed";
import Qualification from "./components/Qualification";
import { checkJoin } from "../../Api";
import { getToken } from "../../utils/auth";

export default {
  name: "CompanySign",
  data() {
    return {
      activeStep: 1,
      joinStatus: "",
    };
  },
  created() {
    //判断入驻状态
    checkJoin({ token: getToken() }).then((res) => {
      if (res.data.message == "checking") {
        this.nextStep(4);
      } else if (res.data.message == "checkSuccess") {
        this.$router.push({ path: "/sign/index" });
      }
    });
  },
  components: {
    SignAgreement,
    CompanyAuthentication,
    SubmitSucceed,
    Qualification,
  },
  computed: {},
  methods: {
    nextStep(step) {
      this.activeStep = step;
    },
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#FFFFFF");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style scoped>
::v-deep .el-step__title.is-finish {
  color: #ff9e58;
}
::v-deep .el-step__head.is-finish {
  color: #ff9e58;
  border-color: #ff9e58;
}
::v-deep .el-steps {
  justify-content: center;
  height: 1.6rem;
  align-items: center;
  background: #f8f9fb;
}
.signDetail {
  min-height: 9rem;
  width: 100%;
  min-width: 1400px;
  padding: 0 3.6rem;
}
.signStepsTitle {
  background: #f8f9fb;
}

.stepForm {
  padding-bottom: 0.3rem;
}
</style>