<!--
 * @FilePath: \zhishe-pc\src\views\sign\components\SignAgreement.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-21 11:46:03
-->
<template>
  <div class="signAgreementBox">
    <div class="signHead">
      <div class="df_Yc">
        <i class="iconfont">&#xe60f;</i>
        <p class="f18 c_33">签约协议</p>
      </div>
      <p class="title">我们对签约有着严格的职业要求及审核标准，请在开始申请签约之前，仔细阅读《签约协议》</p>
    </div>
    <div class="agreementInfo">
      <p class="f14 c_33">
        <span class="c_f46 f14">知设网提醒您：</span>
        在签约知设网（以下简称知设）前，请您务必仔细阅读并透彻理解本协议。您可以选择不使用知设，但如果您使用知设，您的使用行为将被视为对本协议全部内容的认可。
      </p>
      <p>
        1. 签约知设网包括个人签约或企业签约（以下简称签约者），对于所有签约者，不得发布有损国家形象，违凡法律法规的文章、资源或素材。一经发现、证实，知设网将直接取消签约资格，并把证据提交相关部门，由此带来的损失由签约者自己承担。
      </p>
      <p>
        2. 签约者对于所有发布的资源、文章、课程、动态、招聘等享有发布的权利，但由发布的内容而带来的侵权、违归等所造成的损失，知设对其概不负责，亦不承担任何法律责任。
      </p>
      <p>3. 签约者对于发布的内容，可以设置免费或者赞赏，其设置的价格合理性由发布者自行确定。</p>
      <p>4. 签约者通过知设网发布内容链接到的第三方网页内容可能涉嫌侵犯其信息网络传播权，应该及时向知设提出书面权利通知，并提供身份证明、权属证明及详细侵权情况证明。知设在收到上述法律文件后，将会依法尽快断开相关链接内容。</p>
      <p>5. 知设尊重并保护所有使用知设网发布的所有作品，非经您亲自许可或根据相关法律、法规的强制性规定，知设网不会主动地泄露给第三方。</p>
      <p>6. 签约者在知设网发布作品，代表已经同意将其知设产权授于知设网，知设网作为第三方共享平台，如所发布的作品被他人翻录或盗版，录制，我们将协助作者共同维权、保护作品的合法权益。</p>
    </div>
    <div class="redClick">
      <el-checkbox v-model="checked">我已阅读并同意此协议</el-checkbox>
    </div>
    <div class="nextStep" @click="next" :class="{'active':checked}">下一步</div>
  </div>
</template>

<script>
export default {
  name: "SignAgreement",
  data(){
    return{
      checked:false
    }
  },
  methods:{
    next(){
      if(this.checked == true)
      this.$emit('nextStep',2)
    }
  }
}
</script>

<style scoped>
.nextStep.active{
  color: #FFFFFF;
  background: #F46600;
}
.nextStep{
  width: 1.68rem;
  height: 0.42rem;
  line-height: 0.42rem;
  text-align: center;
  color: #333333;
  background: #c0c4cc;
  border-radius: 0.04rem;
  margin: 1rem auto 0;
  cursor: pointer;
}
.redClick{
  margin-top: 0.4rem;
}
.signHead{
  margin-top: 0.35rem;
}
.signHead i{
  color: #F46600;
  font-size: 0.2rem;
  margin-right: 0.15rem;
}
.title{
  margin-top: 0.1rem;
  margin-left: 0.35rem;
  color: #BFBFBF;
  font-size: 0.14rem;
}
.agreementInfo{
  margin-top: 0.2rem;
  padding: 0.3rem;
  box-shadow: 0px 0px 6px rgba(102, 102, 102, 0.16);
}
.agreementInfo p{
  font-size: 0.14rem;
  color: #333333;
  line-height: 0.3rem;
}
</style>