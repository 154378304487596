<!--
 * @FilePath: \zhishe-pc\src\views\sign\components\SubmitSucceed.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2021-12-21 11:44:03
-->
<template>
  <div>
    <div class="succeedBox">
      <i class="iconfont">&#xe605;</i>
      <p>提交成功</p>
    </div>
    <div class="t_c f0404 f18 mt60">
      <p>签约审核中...</p>
      <p>我们将在1-3个工作日内通过短信的方式回复，请注意查收</p>
      <router-link :to="{ path: '/' }"> <p style="color:#f46600;margin-top:0.1rem">返回首页</p> </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "submitSucceed",
};
</script>

<style scoped>
.succeedBox {
  text-align: center;
  margin-top: 2rem;
}
.succeedBox i {
  font-size: 0.6rem;
  color: #f46600;
}
.succeedBox p {
  margin-top: 0.2rem;
  color: #f46600;
  font-size: 0.24rem;
  font-weight: bold;
}
</style>