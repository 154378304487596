<template>
  <div>
    <div class="authenticTitle">
      <i class="iconfont f18">&#xe60f;</i>
      <p class="f18 c_33 f_b">提案者实名认证</p>
    </div>
    <div class="authenticForm">
      <el-form
        :model="authForm"
        :rules="rules"
        ref="authForm"
        label-width="1.5rem"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="authForm.name"
            placeholder="请输入您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input
            v-model="authForm.position"
            placeholder="请填写当前公司的任职职位"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idNumber">
          <el-input
            v-model="authForm.idNumber"
            placeholder="请填写身份证号"
          ></el-input>
        </el-form-item>
        <div class="uploadBox">
          <div>
            <el-form-item label="上传身份证" prop="idImgFront">
              <el-upload
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                accept="image/png,image/jpg,image/jpeg"
                :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
              >
                <img v-if="authForm.idImgFront" :src="authForm.idImgFront" />
                <div v-else>
                  <i class="iconfont c_ee">&#xe60b;</i>
                  <div class="uploadTx">上传身份证人像面</div>
                </div>
                <div class="uploadImg1">
                  <p>重新选择</p>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item prop="idImgReverse">
              <el-upload
                :show-file-list="false"
                :on-success="handleAvatarSuccess1"
                accept="image/png,image/jpg,image/jpeg"
                :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
              >
                <img
                  v-if="authForm.idImgReverse"
                  :src="authForm.idImgReverse"
                />
                <div v-else>
                  <i class="iconfont c_ee">&#xe60b;</i>
                  <div class="uploadTx">上传身份证国徽面</div>
                </div>
                <div class="uploadImg1">
                  <p>重新选择</p>
                </div>
              </el-upload>
            </el-form-item>
          </div>
          <div class="tipBox">
            <p>示例：</p>
            <div>
              <img src="@/assets/image/idImgFront.png" alt="" />
              <img src="@/assets/image/idImgReverse.png" alt="" />
            </div>
          </div>
        </div>
        <div class="dfc">
          <div class="lastStep" @click="last">上一步</div>
          <div class="submit active" @click="submitForm('authForm')">
            下一步
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getProfession } from "../../../Api";
import { getToken } from "../../../utils/auth";
export default {
  name: "CompanyAuthentication",
  data() {
    return {
      hyList: [],
      authForm: {
        name: "",
        position: "",
        idNumber: "",
        idImgFront: "",
        idImgReverse: "",
        token: "",
      },
      rules: {
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        position: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        idNumber: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "你的身份证格式不正确",
          },
        ],
        idImgFront: [{ required: true, message: "请上传身份证正面" }],
        idImgReverse: [{ required: true, message: "请上传身份证反面" }],
      },
    };
  },
  created() {
    this.token = getToken();
    getProfession({ token: getToken() }).then((res) => {
      this.hyList = res.data;
    });
    console.log(this.joinData);
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            token: getToken(),
            idCard: this.authForm.idNumber,
            idCardPhoto: this.authForm.idImgFront,
            idCardPhotoReverse: this.authForm.idImgReverse,
            trueName: this.authForm.name,
            position: this.authForm.position,
          };
          //保留参数到vuex
          this.$store.dispatch("sign/setJoinData", data);
          this.$emit("nextStep", 3);
        } else {
          return false;
        }
      });
    },
    //上传成功
    handleAvatarSuccess(res) {
      if (res.data.message == "uploadSuccess") {
        this.authForm.idImgFront = res.data.filePath;
      }
    },
    handleAvatarSuccess1(res) {
      if (res.data.message == "uploadSuccess") {
        this.authForm.idImgReverse = res.data.filePath;
      }
    },
    last() {
      this.$emit("nextStep", 1);
    },
  },
  computed: {
    ...mapGetters(["joinData"]),
  },
};
</script>

<style scoped>
.uploadBox .el-upload img {
  width: 2.68rem;
  height: 1.7rem;
  z-index: 2000;
  position: absolute;
  left: 0;
}
.uploadBox .el-upload img:hover {
  opacity: 0;
}
.uploadBox .el-upload img:hover + .uploadImg1 {
  opacity: 0.6;
}
.uploadImg1 {
  z-index: 1;
  position: absolute;
  background: #040404;
  opacity: 0;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 2.68rem;
}
.uploadImg1 p {
  text-align: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
}
.submit.active {
  color: #ffffff;
  background: #f46600;
}
.submit {
  width: 1.68rem;
  height: 0.42rem;
  line-height: 0.42rem;
  text-align: center;
  color: #333333;
  background: #c0c4cc;
  border-radius: 0.04rem;
  cursor: pointer;
}
.lastStep {
  width: 1.68rem;
  height: 0.42rem;
  line-height: 0.42rem;
  border: 0.01rem solid #f46600;
  border-radius: 0.04rem;
  text-align: center;
  color: #f46600;
  font-size: 0.16rem;
  margin-right: 1rem;
  cursor: pointer;
}
.tipBox {
  display: flex;
  margin-left: 1.2rem;
  margin-bottom: 1rem;
}
.tipBox p {
  color: #f46600;
  font-size: 0.14rem;
  margin-right: 0.4rem;
}
.tipBox img {
  width: 2.68rem;
  height: 1.7rem;
  display: block;
  margin-bottom: 0.3rem;
}
.uploadBox {
  display: flex;
}
::v-deep .el-upload--text {
  overflow: hidden;
}
::v-deep .el-upload--text i {
  font-size: 0.6rem;
}
::v-deep .el-upload--text img {
  width: 100%;
}
.uploadTx {
  position: absolute;
  width: 100%;
  line-height: 0.2rem;
  font-size: 0.12rem;
  color: #333333;
  bottom: 0.4rem;
}
::v-deep .el-upload {
  width: 2.68rem;
  height: 1.7rem;
  line-height: 1.7rem;
  background: #f8f9fb;
  position: relative;
}
.authenticForm {
  margin-top: 0.4rem;
}
::v-deep .el-form-item {
  margin-bottom: 0.3rem;
}
::v-deep .el-form-item__content {
  width: 3rem;
}
::v-deep .el-input__inner {
  height: 0.38rem;
  line-height: 0.38rem;
}
::v-deep .el-upload {
  width: 2.68rem;
  height: 1.7rem;
  line-height: 1.7rem;
  background: #f8f9fb;
  position: relative;
  border-radius: 0.06rem;
}

.authenticTitle {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.authenticTitle i {
  color: #f46600;
  margin-right: 0.05rem;
}
</style>