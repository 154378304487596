<template>
  <div>
    <div class="authenticTitle">
      <i class="iconfont f18">&#xe60f;</i>
      <p class="f18 c_33 f_b">企业资料认证</p>
    </div>
    <div class="qualificationFormBox">
      <el-form
        :model="qualificationForm"
        label-width="1.5rem"
        :rules="rules"
        ref="qualificationForm"
      >
        <el-form-item label="企业logo" prop="logo">
          <el-upload
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            accept="image/png,image/jpg,image/jpeg"
            :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
          >
            <img
              v-if="qualificationForm.logo"
              :src="qualificationForm.logo"
              class="logoImg"
            />
            <div v-else>
              <i class="iconfont c_ee">&#xe60b;</i>
            </div>
            <div class="uploadImg1">
              <p>重新选择</p>
            </div>
          </el-upload>
        </el-form-item>
        <div class="certificationBox">
          <el-form-item label="企业营业执照" prop="certification">
            <el-upload
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
              accept="image/png,image/jpg,image/jpeg"
              :action="$ROOT_DIR + '/comms/Release/uploadPics?token=' + token"
            >
              <img
                class="certificationImg"
                v-if="qualificationForm.certification"
                :src="qualificationForm.certification"
              />
              <div v-else>
                <i class="iconfont c_ee">&#xe60b;</i>
              </div>
              <div class="uploadImg2">
                <p>重新选择</p>
              </div>
            </el-upload>
          </el-form-item>
          <div class="certificationTip">
            <p>示例：</p>
            <div>
              <img src="@/assets/image/certification.png" alt="" />
            </div>
            <p>
              请上传有年检章的企业执照副本扫描件文件小于2M支持JPG / PNG /
              BMP等格式图片
            </p>
          </div>
        </div>
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            class="w300"
            v-model="qualificationForm.companyName"
            placeholder="请与营业执照的企业名称一致"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="industryInvolved">
          <el-select
            v-model="qualificationForm.industryInvolved"
            placeholder="请选择所属行业"
          >
            <el-option
              v-for="item in hyList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="dfc">
          <div class="lastStep" @click="last">上一步</div>
          <div class="submit active" @click="submitForm('qualificationForm')">
            提交
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getProfession, companyJoin } from "../../../Api";
import { getToken } from "../../../utils/auth";
export default {
  name: "Qualification",
  data() {
    return {
      token: "",
      hyList: [],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      qualificationForm: {
        logo: "",
        certification: "",
        companyName: "",
        industryInvolved: "",
      },
      rules: {
        logo: [{ required: true, message: "请上传企业logo" }],
        certification: [{ required: true, message: "请上传营业执照" }],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        industryInvolved: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.token = getToken();
    getProfession({ token: this.token }).then((res) => {
      this.hyList = res.data;
    });
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res.data.message == "uploadSuccess") {
        this.qualificationForm.logo = res.data.filePath;
      }
    },
    handleAvatarSuccess1(res) {
      if (res.data.message == "uploadSuccess") {
        this.qualificationForm.certification = res.data.filePath;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            token: this.token,
            idCard: this.joinData.idCard,
            idCardPhoto: this.joinData.idCardPhoto,
            idCardPhotoReverse: this.joinData.idCardPhotoReverse,
            trueName: this.joinData.trueName,
            position: this.joinData.position,
            companyLogo: this.qualificationForm.logo,
            companyName: this.qualificationForm.companyName,
            companyPhoto: this.qualificationForm.certification,
            hy: this.qualificationForm.industryInvolved,
          };
          companyJoin(data).then((res) => {
            if (res.data.message == "insertSuccess") {
              this.$emit("nextStep", 4);
            }
          });
        } else {
          return false;
        }
      });
    },
    last() {
      this.$emit("nextStep", 2);
    },
  },
  computed: {
    ...mapGetters(["joinData"]),
  },
};
</script>

<style scoped>
::v-deep .el-upload {
  width: 2.68rem;
  height: 1.7rem;
  line-height: 1.7rem;
  background: #f8f9fb;
  position: relative;
  border-radius: 0.06rem;
}
.logoImg:hover {
  opacity: 0;
}
.logoImg:hover + .uploadImg1 {
  opacity: 0.6;
}

.certificationImg:hover {
  opacity: 0;
}
.certificationImg:hover + .uploadImg2 {
  opacity: 0.6;
}
.uploadImg1 {
  z-index: 1;
  position: absolute;
  background: #040404;
  opacity: 0;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 1.4rem;
}
.uploadImg1 p {
  text-align: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
}

.uploadImg2 {
  z-index: 1;
  position: absolute;
  background: #040404;
  opacity: 0;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 2rem;
}
.uploadImg2 p {
  text-align: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
}
.logoImg {
  z-index: 2000;
  position: absolute;
  left: 0;
  width: 1.4rem;
  height: 1.4rem;
}
.certificationImg {
  z-index: 2000;
  position: absolute;
  left: 0;
  width: 2rem;
  height: 2.6rem;
}
.submit.active {
  color: #ffffff;
  background: #f46600;
}
.submit {
  width: 1.68rem;
  height: 0.42rem;
  line-height: 0.42rem;
  text-align: center;
  color: #333333;
  background: #c0c4cc;
  border-radius: 0.04rem;
  cursor: pointer;
}
.lastStep {
  width: 1.68rem;
  height: 0.42rem;
  line-height: 0.42rem;
  border: 0.01rem solid #f46600;
  border-radius: 0.04rem;
  text-align: center;
  color: #f46600;
  font-size: 0.16rem;
  margin-right: 1rem;
  cursor: pointer;
}
::v-deep .el-form-item {
  margin-bottom: 0.3rem;
}
.certificationBox >>> .el-upload {
  width: 2rem;
  height: 2.6rem;
  line-height: 2.6rem;
  background: #f8f9fb;
  position: relative;
}
::v-deep .el-input__inner {
  height: 0.38rem;
  line-height: 0.38rem;
}
.certificationTip {
  display: flex;
  margin-left: 1.2rem;
}
.certificationTip p:first-child {
  color: #f46600;
  font-size: 0.14rem;
  font-weight: bold;
}
.certificationTip p:last-child {
  margin-top: 1.13rem;
  width: 2.97rem;
  height: 0.34rem;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #f46600;
  opacity: 1;
}
.certificationTip img {
  width: 2rem;
  height: 2.6rem;
  margin: 0 0.6rem 0 0.4rem;
}
.certificationBox {
  display: flex;
}
.qualificationFormBox {
  margin-top: 0.4rem;
}
::v-deep .el-form-item__label {
  text-align: left;
}
.authenticTitle {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.authenticTitle i {
  color: #f46600;
  margin-right: 0.05rem;
}
.uploadBox {
  display: flex;
}
::v-deep .el-upload--text {
  overflow: hidden;
}
::v-deep .el-upload--text i {
  font-size: 0.6rem;
}
::v-deep .el-upload--text img {
  width: 100%;
}
::v-deep .el-upload {
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1.4rem;
  background: #f8f9fb;
  position: relative;
}
</style>